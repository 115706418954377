import React, { Component } from 'react';
import styled from 'styled-components'
import './index.css'
import { RouterView } from './router'

const AppWrap = styled.div`

`

class App extends Component {

  render() {
    return (
      <AppWrap>
        <RouterView
          routes={[
            'register',
            'login',
            'admin'
        ]}/>
      </AppWrap>
    );
  }
}

export default App;

