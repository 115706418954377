import React from 'react'
import Loadable from 'react-loadable'
import { Router, Link } from '@reach/router'

const Loading = () => {
  return null
}

const routes = [{
  name: 'login',
  path: '/',
  component: AsyncComponent('./views/Login'),
},
{
  name: 'register',
  path: '/register',
  component: AsyncComponent('./views/Register'),
},
{
  name: 'admin',
  path: '/admin',
  component: AsyncComponent('./views/admin/Layout'),
    children: [
      {
        name: 'submitTheme',
        path: 'submitTheme',
        component: AsyncComponent('./views/admin/SubmitTheme')
      },
      {
        name: 'addClient',
        path: 'addClient',
        component: AsyncComponent('./views/admin/AddClient')
      },
      {
        name: 'FAQ',
        path: 'FAQ',
        component: AsyncComponent('./views/admin/FAQ')
      },
      {
        name: 'redemptionCode',
        path: 'redemptionCode',
        component: AsyncComponent('./views/admin/RedemptionCode')
      },
      {
        name: 'redemptionCodeList',
        path: 'redemptionCode/themes/:themeId',
        component: AsyncComponent('./views/admin/RedemptionCodeList')
      },
      {
        name: 'activityOrderList',
        path: 'activityOrder',
        component: AsyncComponent('./views/admin/ActivityOrderList')
      },
      {
        name: 'activityOrderInfo',
        path: 'activityOrder/:orderId',
        component: AsyncComponent('./views/admin/ActivityOrderInfo')
      },
      {
        name: 'audit',
        path: 'audit',
        component: AsyncComponent('./views/admin/Audit')
      },
      {
        name: 'author',
        path: 'author',
        component: AsyncComponent('./views/admin/Author')
      },
      {
        name: 'authorThemeList',
        path: 'author/:authorId/themes',
        component: AsyncComponent('./views/admin/AuthorThemeList')
      },
      // {
      //   name: 'allowListManage',
      //   path: 'author/allowListManage',
      //   component: AsyncComponent('./views/admin/AllowListManage')
      // },
      {
        name: 'authorNoteList',
        path: 'author/noteList',
        component: AsyncComponent('./views/admin/AuthorNoteList')
      },
      {
        name: 'advancedSearch',
        path: 'author/advancedSearch',
        component: AsyncComponent('./views/admin/AdvancedSearch')
      },
      {
        name: 'clientAuthNumber',
        path: 'author/clientAuthNumber',
        component: AsyncComponent('./views/admin/ClientAuthNumber')
      },
      {
        name: 'useClientAuth',
        path: 'author/useClientAuth',
        component: AsyncComponent('./views/admin/UseClientAuth')
      },
    ]
  },
]

const flattenRouteMap = flatten(routes)

export function RouterView(props) {
  const { routes, ...res } = props

  return (
    <Router>
      {
        routes.map(routeName => {
          const { component: Component, path, name } = flattenRouteMap[routeName]
          return <Component other={{ ...res }} key={name} path={`${path}/*`} /> // 为了 Router 组件可以嵌套，path 要默认带个 /*
        })
      }
    </Router>
  )
}

export function RouterLink(props) {
  const { to, children } = props
  let { query = {}, params = {}, name, path } = to
  const route = flattenRouteMap[name]

  path = path ? path : route.path

  const querystring = parseQueryToString(query)

  path = parseParamsToString(path, params)

  path = querystring ? `${path}?${querystring}` : path

  return (
    <Link to={path}>{children}</Link>
  )
}



function parseQueryToString(query) {
  return Object.keys(query).map(key => {
    return `${key}=${query[key]}`
  }).join('&')
}

function parseParamsToString(path, params) {
  return path.replace(/:([^/]+)/g, (...args) => {
    const key = args[1]
    return params[key]
  })
}

function flatten(routes) {
  let map = {}
  function walk(routes) {
    routes.forEach((item) => {
      map[item.name] = item
      if (item.children && item.children.length) {
        walk(item.children)
      }
    })
  }

  walk(routes)
  return map
}

// function AsyncComponent (path, name = 'home') {
//   const func = new Function('Loadable', 'Loading', `
//       return Loadable({
//         loader: () => import(/* webpackChunkName: "${name}" */ '${path}'),
//         loading: Loading
//       })
//   `)
//   return func(Loadable, Loading)
// }

function AsyncComponent(path) {
  return Loadable({
    loader: () => import(`${path}`),
    loading: Loading
  })
}